
.glow-on-hover {
  width: '100%';
  height: '100%';
  cursor: pointer;
  position: relative;
  z-index: 0;
}
.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #49B6FF, #A8DCFF, #D9ECFD);
  position: absolute;
  top: 5px;
  left: 6px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: 103%;
  height: 85%;
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
}
.glow-on-hover:active {
  color: #000
}
.glow-on-hover:active:after {
  background: transparent;
}
.glow-on-hover:before {
  opacity: 1;
}
.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: -5px;
  top: 0;
}
@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}